import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { navigate } from "gatsby"

const Success = props => {
  const [modal, setModal] = useState(true);
  const [backdrop, setBackdrop] = useState(true);
  const [open, setOpen] = useState(true);

  function handleClose() {
    setOpen(false);
    navigate("/");
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Thank You"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you for contributing to The Foundation to Support the Young Kings, Inc. 
            With this donation, you have joined a growing group dedicated to the future of young men of color in the District of Columbia.
            <div>A receipt will be emailed to you shortly.</div>
            <div>If you are interested in becoming a mentor, please <a href="mailto:info@ykfinc.org">contact us today</a>.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Success;