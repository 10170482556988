import React, { useState } from 'react';
import { injectStripe } from 'react-stripe-elements';
import axios from 'axios';
import { Grid, TextField, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberFormat from 'react-number-format';

import AddressSection from './AddressSection';
import CardSection from './CardSection';
import Button from '@material-ui/core/Button';
import Success from './Success';
import { Section, Container } from '@components/global';
import { lightBlue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative"
  },
  fullWidth: {
    width: '100%',
  },
  donateButton: {
    whiteSpace: 'nowrap',
    border: 0,
    outline: 0,
    position: 'relative',
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    padding: '0 14px',
    boxShadow: '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    color: '#fff',
    borderRadius: '4px',
    fontSize: '15px',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '0.025em',
    backgroundColor: '#6772e5',
    textDecoration: 'none',
    transition: 'all 150ms ease',
    marginTop: '10px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#7795f8',
      transform: 'translateY(-1px)',
      boxShadow: '0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)',
    },
  },
  buttonBusy: {
    color: lightBlue[500],
    top: '8px',
    left: '-56px',
    color: '#03a9f4',
    position: 'relative',
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

const DonateForm = props => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [email, setEmail] = useState('');
  const [amount, setAmount] = useState();
  const [success, setSuccess] = useState(false);
  const [schedule, setSchedule] = useState('');
  const [isBusy, setIsBusy] = useState(false);

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'address1':
        setAddress1(e.target.value);
        break;
      case 'address2':
        setAddress2(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'state':
        setState(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'amount':
        setAmount(e.target.value * 100);
        break;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isBusy) {
      setIsBusy(true);
    }

    props.stripe.createToken({
      name,
      email,
      address_city: city,
      address_line1: address1,
      address_line2: address2,
      address_state: state,
      address_country: "US"
    })
      .then(({ token }) => {
        const charge = JSON.stringify({
          token,
          charge: {
            amount,
            currency: 'usd',
            name,
            email,
          },
        })
        axios.post('/.netlify/functions/stripeCharge', charge)
          .then(() => {
            setSuccess(true);
            setIsBusy(false);
          }
          )
          .catch(function (error) {
            alert(error);
            setIsBusy(false);
          })
      });
  }

  return (
    <Paper elevation={3} style={{padding: '15px'}}>
    <Wrapper>
      
      <Grid container justify="center">
        <form onSubmit={handleSubmit} id="form-css" className={classes.fullWidth}>
          {success === false ?
            <div>
              <AddressSection handleChange={handleChange} />
              <Grid item>
                <CardSection
                  disabled={isBusy}
                  onChange={handleChange} />
              </Grid>
              <h3>Donation Amount</h3>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <label style={{ paddingRight: '15px' }}>$</label>
                </Grid>
                <Grid item xs>
                  <input type="number" name="amount" type="text" placeholder="Amount" required onChange={handleChange} disabled={isBusy} />
                </Grid>
              </Grid>

              <Grid item xs>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isBusy}
                  variant="contained"
                >
                  Submit
                    </Button>
                {isBusy && <CircularProgress size={24} className={classes.buttonBusy} />}
              </Grid>
            </div>
            :
            <Success />
          }
        </form>
      </Grid>
      
    </Wrapper>
    </Paper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 15px;
  min-height: 66vh;
`;

export default injectStripe(DonateForm);