import React, { useState } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Layout from '@components/common/Layout';
import { Section, Container } from '@components/global';
import DonateComponent from '../components/donate/DonateComponent';
import CreditCardButton from '../components/donate/CreditCardButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paypalButton: {
    width: '165px',
    backgroundColor: '#ffc439',
    height: '25px',
    borderRadius: '13px',
    color: 'black',
    fontSize: '13px',
    fontWeight: '900',
    fontFamily: 'sans-serif',
  },
  reset: {
    display: 'initial',
    margin: 'initial',
    maxWidth: 'initial',
    padding: 'initial',
    fontSize: 'initial',
    fontfamily: 'initial',
    boxShadow: 'initial',
    border: 'initial',
    outline: 'initial',
    borderRadius: 'initial',
    background: 'initial',
  },
}));

export default function () {
  const classes = useStyles();
  const [showCreditCardForm, setShowCreditCardForm] = useState(false);

  return (
    <Layout>
      <Section>
        <Container>
          <Grid container justify="center" className={classes.root}>
            <Grid item xs={8}>
              <Header>Donate</Header>
            </Grid>
            <Grid item xs={8}>
              <Paragraph>Thank you for your interest in contributing to The Foundation to Support the Young Kings, Inc.</Paragraph>
              <Paragraph>With your support, we can help the Ron Brown College Preparatory High School educate and empower young men of color.
                Your donation will make a difference in the lives of RBHS students, molding them as they develop into our future leaders.</Paragraph>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid container justify="center" direction="row" spacing={2} justify="center">
            <Grid item>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="TYADK8A9ZDWMC" />
                <PayPalButton name="submit" type="submit" title="PayPal - The safer, easier way to pay online!">Donate via PayPal</PayPalButton>
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form>
              {/* <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_donations" />
                <input type="hidden" name="business" value="S4Z49USQVGF7Y" />
                <input type="hidden" name="currency_code" value="USD" />
                <PayPalButton name="submit" type="submit" title="PayPal - The safer, easier way to pay online!">Donate via PayPal</PayPalButton>
                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
              </form> */}
            </Grid>
            <Grid item>
              <CreditCardButton onClick={() => setShowCreditCardForm(!showCreditCardForm)}></CreditCardButton>
            </Grid>
          </Grid>
        </Container>
      </Section>
      {showCreditCardForm &&
        <Section>
          <Container>
            <Grid container justify="center" className={classes.root}>
              <Grid item xs={8}>
                <DonateComponent></DonateComponent>
              </Grid>
            </Grid>
          </Container>
        </Section>}
    </Layout>
  );
}

const Header = styled.h2`
  padding-bottom: 25px
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
`;

const PayPalButton = styled.button`
    width: 165px;
    background-color: #ffc439;
    height: 25px;
    border-radius: 13px;
    color: black;
    font-size: 13px;
    font-weight: 900;
    font-family: sans-serif;
`;