import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CardElement from 'react-stripe-elements';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  fullWidth: {
    width: '100%',
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
}

function AdressSection(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <div className={classes.root}>
      <div>
        <h3>Billing Address</h3>
        <Grid container direction="column">
          <Grid item xs>
            <input name="name" type="text" placeholder="Full Name" required onChange={props.handleChange} className={classes.fullWidth} />
          </Grid>
          <Grid item xs>
            <input type="email" name="email" onChange={props.handleChange} placeholder="Email address" required className={classes.fullWidth} />
          </Grid>
          <Grid item xs>
            <input type="text" name="address1" onChange={props.handleChange} placeholder="Street Address" required className={classes.fullWidth} />
          </Grid>
          <Grid item xs>
            <input type="text" name="address2" onChange={props.handleChange} placeholder="Apt., ste, bldg. (optional)" className={classes.fullWidth} />
          </Grid>
          <Grid item xs>
            <input type="text" name="city" onChange={props.handleChange} placeholder="City" className={classes.fullWidth} required />
          </Grid>
          <Grid item xs>
            <input type="text" name="state" onChange={props.handleChange} placeholder="State" required />
          </Grid>
        </Grid>
      </div>
      
    </div>
  );
}

export default AdressSection;





/*import React from 'react';
import { CardElement } from 'react-stripe-elements';
import { Row, Col, Input } from 'reactstrap';

const AddressSection = (props) => {

    return (
    <div>
      <Row>
        <Col xs="6" sm="6" md="6">
          <label className="w-100">
            Name
            <input className="w-100" type="text" name="name" onChange={props.handleChange} placeholder="Jane Doe" required />
          </label>
        </Col>
        <Col xs="6" sm="6" md="6">
          <label className="w-100">
            Email
            <input className="w-100" type="email" name="email" onChange={props.handleChange} placeholder="jane.doe@example.com" required />
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs="6" sm="6" md="6">
          <label className="w-100">
            Address
            <input className="w-100" type="text" name="address" onChange={props.handleChange} placeholder="100 Legends Way" required />
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs="4" sm="4" md="6">
          <label className="w-100">
            City
            <input className="w-100" type="text" name="city" onChange={props.handleChange} placeholder="Boston" required />
          </label>
        </Col>
        <Col xs="3" sm="3" md="6">
          <label className="w-100">
            State
            <input className="w-100" type="text" name="state" onChange={props.handleChange} placeholder="MA" required />
          </label>
        </Col>
        <Col xs="3" sm="3" md="6">
          <label className="w-100">
            Amount
            <input name="amount" onChange={props.handleChange} placeholder="$" />
          </label>
        </Col>
      </Row>
    </div>
  );
}

export default AddressSection;*/