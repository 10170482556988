import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',

  },
  margin: {
    margin: theme.spacing(1),
  },
  btn: {
    width: '150px',
    backgroundColor: 'black',
    height: '25px',
    borderRadius: '13px',
    color: 'white',
    fontSize: '13px',
    fontWeight: '900',
    fontFamily: 'sans-serif',
  },
}));

const CreditCardButton = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <button className={classes.btn} onClick={props.onClick}>Donate via Credit Card</button>
    </div>
  )
}

export default CreditCardButton;