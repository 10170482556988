import React, { useState, useEffect } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import DonateForm from './DonateForm';

const DonateComponent = () => {
  const [stripe, setStripe]= useState(null);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(`pk_live_qjlJVnI6EvhFVJWnJEjsZlCk00mgzN9Yta`));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(`pk_live_qjlJVnI6EvhFVJWnJEjsZlCk00mgzN9Yta`));
      });
    }
  }, []);
  
  return (
    <StripeProvider stripe={stripe}>
      <Elements>
        <DonateForm />
      </Elements>
    </StripeProvider>
    
  )
}

export default DonateComponent;