import React from 'react';
import { CardElement, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

const CardSection = (props) => {
  const classes = useStyles();


  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '&::placeholder': {
            color: '#aab7c4',
          },
          padding,
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };
  return (
    <div className={classes.root}>
    <Grid container alignItems="center" justify="center">
      <Grid item xs>
            <h3>Card Details</h3>
            <CardElement
              {...createOptions(props.fontSize)}
            />
          
      </Grid>
    </Grid>
      
    </div>
  );
}

export default CardSection;